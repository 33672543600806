/* eslint-disable max-lines */
/* global $ */
import Axios from 'axios';
import serialize from 'form-serialize';
import delegate from 'delegate';
import debounce from 'lodash.debounce';
import gsap from 'gsap';

import type { BaseResponse } from '../types';

type CatalogResponse = BaseResponse & {
    data: {
        title: string;
        catalogHtml: string;
        filterHtml: string;
        crumbs: string;
        countHtml?: string;
        newUrl?: string;
        action?: string;
        icons?: Record<string, any>;
        filterEmpty: boolean;
    };
};

type CatalogAmountResponse = BaseResponse & {
    data: {
        amount: number;
        filterHtml: string;
    };
};

const { CancelToken } = Axios;
let cancelActualRequest: any;

const priceDelimiter = (num: number) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

document.addEventListener('DOMContentLoaded', () => {
    const form = document.querySelector<HTMLFormElement>('form.js-catalog-filter');

    // if (!form) {
    //     return;
    // }

    const formContent = document.querySelector<HTMLElement>('.js-catalog-filter-content');
    const catalogTitle = document.querySelector('.js-catalog-title');
    const catalogList = document.querySelector('.js-catalog-list');
    const countElement = document.querySelector('.js-count-block');
    const breadcrumbs = document.querySelector('.js-breadcrumbs-list');
    const catalogLinks = Array.from(document.querySelectorAll<HTMLAnchorElement>('a.js-catalog-category-link'));
    let timer: NodeJS.Timeout;
    const viewInputs = Array.from(document.querySelectorAll<HTMLInputElement>('input.js-catalog-view-input'));
    const initialSortingSelect = document.querySelector<HTMLSelectElement>('select.js-catalog-sorting');
    const sortingSelects = Array.from(document.querySelectorAll<HTMLSelectElement>('select.js-catalog-sorting'));
    const submitBtn = document.querySelector<HTMLButtonElement>('.js-filter-submit-btn');
    let amountElements = Array.from(document.querySelectorAll('.js-filter-products-amount'));
    let isFirstTranslation = true;

    const filterTabContainer = document.querySelector('.js-filter-tab');

    const clearInputs = (formEl: HTMLFormElement) => {
        Array.from(
            formEl.querySelectorAll<HTMLInputElement>('[name]:not([type="submit"]):not([type="reset"])'),
        ).forEach((input) => {
            if (input.type === 'checkbox') {
                input.checked = false;
            } else if (
                input.type !== 'hidden' &&
                !input.hasAttribute('data-range-min') &&
                !input.hasAttribute('data-range-max')
            ) {
                input.value = '';
            }
            input.dispatchEvent(new Event('blur'));
        });
    };

    function setLoadingState() {
        setTimeout(() => {
            clearTimeout(timer);

            if (submitBtn) {
                submitBtn.disabled = true;
            }

            timer = setTimeout(() => {
                catalogList?.classList.add('loading');
            }, 200);
        }, 0);
    }

    function removeLoadingState() {
        setTimeout(() => {
            clearTimeout(timer);
            catalogList?.classList.remove('loading');

            if (submitBtn) {
                submitBtn.disabled = false;
            }
        }, 0);
    }

    function serializeSortingSelect() {
        return initialSortingSelect ? { [initialSortingSelect.name]: initialSortingSelect.value } : {};
    }

    function serializeViewInput() {
        const checkedInput = viewInputs.find((input) => input.checked);
        return checkedInput ? { [checkedInput.name]: checkedInput.value } : {};
    }

    function initRangePicker() {
        if (form) {
            const minInput = form.querySelector<HTMLInputElement>('[data-range-min]');
            const maxInput = form.querySelector<HTMLInputElement>('[data-range-max]');

            if (minInput) {
                minInput.value = priceDelimiter(parseFloat(minInput.value));
            }

            if (maxInput) {
                maxInput.value = priceDelimiter(parseFloat(maxInput.value));
            }

            if ((window as any).filterSlider?.noUiSliderInstance) {
                (window as any).filterSlider?.noUiSliderInstance.on('change.one', () =>
                    onInputChange({ delegateTarget: maxInput }),
                );

                (window as any).filterSlider?.noUiSliderInstance.on('update.one', () => {
                    if (minInput) {
                        minInput.value = priceDelimiter(parseFloat(minInput.value));
                    }

                    if (maxInput) {
                        maxInput.value = priceDelimiter(parseFloat(maxInput.value));
                    }
                });
            }
        }
    }

    function getFormData() {
        if (form) {
            const formData = serialize(form, { hash: true });
            const minInputs = Array.from(form.querySelectorAll<HTMLInputElement>('input[data-range-min]'));
            const maxInputs = Array.from(form.querySelectorAll<HTMLInputElement>('input[data-range-max]'));

            minInputs.forEach((input) => {
                if (typeof formData[input.name] === 'string') {
                    formData[input.name] = (formData[input.name] as string).replace(/\s/g, '');
                }

                const parent = input
                    .closest<HTMLElement>('[data-range-filter]')
                    ?.querySelector<HTMLElement>('[data-range-slider]');

                if (parent?.dataset.min && input.value.replace(/\s/g, '') === parent.dataset.min) {
                    delete formData[input.name];
                }
            });

            maxInputs.forEach((input) => {
                if (typeof formData[input.name] === 'string') {
                    formData[input.name] = (formData[input.name] as string).replace(/\s/g, '');
                }

                const parent = input
                    .closest<HTMLElement>('[data-range-filter]')
                    ?.querySelector<HTMLElement>('[data-range-slider]');

                if (parent?.dataset.max && input.value.replace(/\s/g, '') === parent.dataset.max) {
                    delete formData[input.name];
                }
            });

            return formData;
        }

        return {};
    }

    async function fetchData<T = any>(url: string, action: string, serializeForm = true) {
        return Axios.get<T>(url, {
            params: {
                action,
                ...(serializeForm ? getFormData() : {}),
                ...(serializeForm ? serializeSortingSelect() : {}),
                ...(serializeForm ? serializeViewInput() : {}),
                is_ajax: 'Y',
                ajax: action === 'getAmount' ? 'y' : 'n',
            },
            cancelToken: new CancelToken((c) => {
                cancelActualRequest = c;
            }),
        });
    }

    async function fetchProductsAmountData(url: string, serializeForm = true) {
        cancelActualRequest?.();

        try {
            const response = await fetchData<CatalogAmountResponse>(url, 'getAmount', serializeForm);
            const { amount, filterHtml } = response.data.data;

            if (form) {
                if ((window as any).filterSlider) {
                    (window as any).filterSlider.destroy();
                }
                if (formContent) {
                    formContent.innerHTML = filterHtml;
                }
                amountElements = Array.from(form.querySelectorAll('.js-filter-products-amount'));
                const rangeFilterEl = form.querySelector('[data-range-filter]');

                if (rangeFilterEl) {
                    (window as any).filterSlider = new (window as any).RangeFilter(rangeFilterEl);
                    initRangePicker();
                }
            }

            amountElements.forEach((el) => {
                el.innerHTML = `${amount || 0}`;
            });
        } catch (err) {
            if (!Axios.isCancel(err)) {
                throw err;
            }
        }
    }

    async function fetchActualData(url: string, serializeForm = true) {
        cancelActualRequest?.();
        setLoadingState();

        try {
            const response = await fetchData<CatalogResponse>(url, 'getCatalogData', serializeForm);
            const { catalogHtml, filterHtml, title, crumbs, newUrl, action, icons, filterEmpty, countHtml } =
                response.data.data;

            if (catalogList) {
                catalogList.innerHTML = catalogHtml || '';
                (window as any).lazyloadItems?.('[data-lazyload-first]');
            }

            if (form) {
                if ((window as any).filterSlider) {
                    (window as any).filterSlider.destroy();
                }
                if (formContent) {
                    formContent.innerHTML = filterHtml;

                    if (filterEmpty) {
                        filterTabContainer?.classList.add('hide');
                        // todo
                        ($ as any)('[href="#asideFrame_1"]').tab('show');
                    } else {
                        filterTabContainer?.classList.remove('hide');
                    }
                }
                isFirstTranslation = true;
                amountElements = Array.from(form.querySelectorAll('.js-filter-products-amount'));
                const rangeFilterEl = form.querySelector('[data-range-filter]');

                if (rangeFilterEl) {
                    (window as any).filterSlider = new (window as any).RangeFilter(rangeFilterEl);
                    initRangePicker();
                }
            }

            if (catalogTitle) {
                catalogTitle.textContent = title;
            }

            if (breadcrumbs && crumbs) {
                breadcrumbs.innerHTML = crumbs;
            }

            if (countElement && countHtml) {
                countElement.innerHTML = countHtml;
            }

            if (newUrl) {
                window.history.pushState(null, title || '', newUrl);
            }

            if (title) {
                document.title = title;
            }

            if (form && action) {
                form.action = action;
            }

            if (icons) {
                for (let key in icons) {
                    let values = icons[key];
                    values.forEach((id: string) => {
                        let elements = document.querySelectorAll('.' + key + '[data-item-id="' + id + '"]');
                        if (elements !== null) {
                            elements.forEach((element) => {
                                element.classList.add('is-active');
                            });
                        }
                    });
                }
            }
        } catch (err) {
            if (!Axios.isCancel(err)) {
                throw err;
            }
        } finally {
            submitBtn?.classList.add('is-hidden');
            removeLoadingState();
        }
    }

    function onChange(this: HTMLInputElement | null) {
        if (form) {
            if (submitBtn && this) {
                if (window.matchMedia('(min-width: 1154px)').matches) {
                    const filterItem = this.hasAttribute('data-range-max') ? this : this.closest('.filter__item');

                    if (filterItem) {
                        const submitBtnRect = submitBtn.getBoundingClientRect();
                        const formRect = form.getBoundingClientRect();
                        const filterItemRect = filterItem.getBoundingClientRect();

                        const x = filterItemRect.right - formRect.left + 15;
                        const y =
                            filterItemRect.top - formRect.top + (filterItemRect.height - submitBtnRect.height) / 2;

                        if (isFirstTranslation) {
                            submitBtn.style.transform = `translate(${x}px, ${y}px)`;
                            isFirstTranslation = false;
                        } else {
                            gsap.to(submitBtn, {
                                duration: 0.6,
                                x,
                                y,
                                ease: 'back.inOut(2)',
                                overwrite: true,
                            });
                        }
                    }
                } else {
                    gsap.set(submitBtn, { clear: 'all' });
                }
            }

            fetchProductsAmountData(form.action).finally(() => {
                submitBtn?.classList.remove('is-hidden');
            });
        }
    }

    function onFilterSubmit(event: any) {
        event.preventDefault();

        if (form) {
            fetchActualData(form.action);
        }
    }

    const debouncedOnChange = debounce(onChange, 600);

    function onInputChange(event: any) {
        cancelActualRequest?.();
        debouncedOnChange.call(event.delegateTarget);
    }

    function resetFilter() {
        setTimeout(() => {
            if (form) {
                clearInputs(form);
                fetchActualData(form.action);
                (window as any).filterSlider?.noUiSliderInstance?.reset();
            }
        }, 1);
    }

    if (form) {
        form.addEventListener('submit', onFilterSubmit);
        form.addEventListener('reset', resetFilter);
        delegate(form, 'input', 'change', onInputChange);
        initRangePicker();
    }

    viewInputs.forEach((input) => {
        input.addEventListener('change', () => {
            if (form) {
                fetchActualData(form.action);
            }
        });
    });

    sortingSelects.forEach((select) => {
        select.addEventListener('change', () => {
            if (form) {
                fetchActualData(form.action);
            }
        });
    });

    catalogLinks.forEach((link) => {
        link.addEventListener('click', (event) => {
            event.preventDefault();
            catalogLinks.forEach((item) => {
                item.classList.remove('is-active');
            });
            fetchActualData(link.href);
            link.classList.add('is-active');
            if (form) {
                form.action = link.href;
            }
            $('.js-subcategory-toggle-text').text($('.js-subcategory-list-btn-text', link).first().text().trim());
        });
    });
});
