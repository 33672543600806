document.addEventListener("DOMContentLoaded", () => {
    const menuActiveItemList = document.querySelectorAll(".category-list__link.menu-link-active");

    if (menuActiveItemList.length > 0) {
        const menuActiveItemArray = Array.from(menuActiveItemList);
        const lastItem = menuActiveItemArray.pop();

        if (lastItem) {
            lastItem.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }
    }
});
