import './webpack-imports';
// import './sentry';
import './polyfills';
import { registerCustomElements } from './custom-elements';
import createRoom from './components/room';
import dropdowns from './inits/dropdowns';
import masks from './inits/masks';
import datepicker from './inits/datepicker';
import favoritesChangeView from './inits/favorites-change-view';
import historyBack from './inits/history-back';
import innRequest from './inits/inn-request';
import audio from './inits/audio';
import './inits/catalog';
import './inits/ajax-pagination';
import './inits/scroll-restoration';
import './inits/menu-scroll';
import '../modules/validator';
import contactsPage from './utils/contactsPage';
import { lazyload } from './inits/lazy-load';
import eventsSwiper from './inits/sliders/events-swiper';
import industrySolutionSwiper from './inits/sliders/industry-solutions-swiper';
import completedProjectsSwiper from './inits/sliders/completed-projects-swiper';
import madeInDipaulSwiper from './inits/sliders/made-in-dipaul-swiper';
import pressCenterSwiper from './inits/sliders/press-center-swiper';

document.addEventListener('DOMContentLoaded', () => {
    document.documentElement.classList.add('js-ready');
    registerCustomElements();
    createRoom();
    dropdowns.init();
    masks.init();
    datepicker.init();
    favoritesChangeView.init();
    historyBack.init();
    innRequest.init();
    audio.init();
    contactsPage.init();
    lazyload.update();

    // Main Scene Hovers

    const mainSceneHoverImages = Array.from(document.querySelectorAll<HTMLElement>('[data-static-image]'));
    const mainSceneHoverLinks = Array.from(document.querySelectorAll<HTMLElement>('[data-static-name]'));

    const mainEventsWrapper = Array.from(document.querySelectorAll('.js-main-events-slider-wrapper'));
    if (mainEventsWrapper.length) {
        mainEventsWrapper.forEach((swiper) => {
            eventsSwiper.init(swiper as HTMLElement);
        });
    }

    const industrySolutionWrapper = Array.from(document.querySelectorAll('.js-industry-solutions-slider-wrapper'));
    if (industrySolutionWrapper.length) {
        industrySolutionWrapper.forEach((swiper) => {
            industrySolutionSwiper.init(swiper as HTMLElement);
        });
    }

    const completedProjectsWrapper = Array.from(document.querySelectorAll('.js-completed-projects-wrapper'));
    if (completedProjectsWrapper.length) {
        completedProjectsWrapper.forEach((swiper) => {
            completedProjectsSwiper.init(swiper as HTMLElement);
        });
    }

    const madeInDipaulWrapper = Array.from(document.querySelectorAll('.js-made-in-dipaul-wrapper'));
    if (madeInDipaulWrapper.length) {
        madeInDipaulWrapper.forEach((swiper) => {
            madeInDipaulSwiper.init(swiper as HTMLElement);
        });
    }

    const pressCenterWrapper = Array.from(document.querySelectorAll('.js-press-center-wrapper'));
    if (pressCenterWrapper.length) {
        pressCenterWrapper.forEach((swiper) => {
            pressCenterSwiper.init(swiper as HTMLElement);
        });
    }

    mainSceneHoverLinks.forEach((link) => {
        link.addEventListener('mouseenter', () => {
            const name = link.dataset.staticName;

            mainSceneHoverImages.forEach((img) => {
                img.classList.add('is-hidden');
            });

            if (name) {
                const images = mainSceneHoverImages.filter((img) => img.dataset.staticImage === name);

                images.forEach((image) => {
                    image.classList.remove('is-hidden');
                });
            }
        });

        link.addEventListener('mouseleave', () => {
            mainSceneHoverImages.forEach((img) => {
                img.classList.add('is-hidden');
            });
        });
    });
});
