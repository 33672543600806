import './room-v5.named-worker';
import { createOffscreenCanvas } from 'offscreen-canvas-proxy';
import { Remote } from 'comlink';
import gsap from 'gsap';

import { workerUrl } from './url';
import type { Canvas } from './module';
import { initStats } from './stats';
import type { Dimensions } from './types';

type RoomCanvas = Canvas & {
    onResize: (dimensions: Dimensions) => Promise<void>;
};

const searchParams = new URLSearchParams(window.location.search.replace('?', ''));

function getInitialSlide() {
    switch (searchParams.get('room')) {
        case 'manufacturing':
            return 1;
        case 'experiments':
            return 2;
        case 'service':
            return 3;
        default:
            return 0;
    }
}

export default async () => {
    const canvas = document.querySelector<HTMLCanvasElement>('canvas.js-room-canvas');
    const roomContainer = document.querySelector<HTMLElement>('.js-room-container');
    const commonRoomEl = document.querySelector<HTMLElement>('.js-common-room');

    let canvasRect = roomContainer?.getBoundingClientRect();
    let offscreen: Remote<RoomCanvas> | null;
    let isChange = false;
    const animationTime = 600;
    let swiper: any;
    const worldLinks = Array.from(document.querySelectorAll<HTMLElement>('.js-room-link--common'));
    let isWorldActive = !searchParams.get('room');

    worldLinks.forEach((link, i) => {
        link.addEventListener('click', () => {
            document.dispatchEvent(new CustomEvent('transition-to-room', { detail: i }));

            setTimeout(() => {
                document.dispatchEvent(new CustomEvent('transition-to-room-complete', { detail: i }));
            }, 1400);
        });
    });

    function onVisibilityChange() {
        offscreen?.setState({ documentVisible: document.visibilityState === 'visible' });
    }

    async function onResize() {
        if (canvas && !window.matchMedia('(max-width: 1024px)').matches && !offscreen) {
            if (commonRoomEl) {
                commonRoomEl.classList.add('webgl-initialized');
            }

            offscreen = await createOffscreenCanvas<RoomCanvas>(
                { canvas, workerUrl },
                {
                    dpr: Math.min(window.devicePixelRatio, 2),
                    width: canvasRect?.width || 0,
                    height: canvasRect?.height || 0,
                    documentVisible: true,
                },
                true,
            );

            // do not remove - it fixes 'undefined' bug
            offscreen.getState();
        }

        if (roomContainer) {
            canvasRect = roomContainer.getBoundingClientRect();
            offscreen?.onResize({ width: canvasRect.width, height: canvasRect.height });
        }
    }

    const roomSliderPagination = document.querySelector('.js-room-slider-pagination');
    const worldRoom = document.querySelector<HTMLElement>('.js-room[data-room-name="world"]');

    document.addEventListener('transition-to-room', (event: any) => {
        if (isChange) {
            return;
        }

        isChange = true;

        function getCameraPosition() {
            switch (event.detail) {
                case 0:
                    return { x: 7.307, y: -42.862, z: -6.864 };
                case 1:
                    return { x: -6.945, y: -42.862, z: -2.454 };
                case 2:
                    return { x: 12.66, y: -42.862, z: 2.027 };
                case 3:
                    return { x: 4.154, y: -42.862, z: 4.154 };
                default:
                    return { x: 0, y: 0, z: 0 };
            }
        }

        offscreen?.leaveScene(getCameraPosition());

        const tl = gsap.timeline({
            onStart: () => {
                setTimeout(() => {
                    swiper?.slideTo(event.detail);
                }, 1200);
            },
            onComplete: () => {
                offscreen?.setScene(event.detail);

                if (worldRoom) {
                    worldRoom.classList.add('is-hidden');
                }

                if (offscreen) {
                    offscreen?.enterScene(event.detail).then(() => {
                        isChange = false;
                    });
                }

                swiper.slides[event.detail].classList.add('is-show');
                roomSliderPagination?.classList.add('is-show');
                isWorldActive = false;
            },
        });

        tl.to({}, { duration: 0.3 }).to({}, { duration: 1.2 });
    });

    function disableSwiper(swiperInstance: any) {
        // swiperInstance.autoplay.stop();
        swiperInstance.allowSlideNext = false;
        swiperInstance.allowSlidePrev = false;
        swiperInstance.allowTouchMove = false;
    }

    function enableSwiper(swiperInstance: any) {
        // swiperInstance.autoplay.start();
        swiperInstance.allowSlideNext = true;
        swiperInstance.allowSlidePrev = true;
        swiperInstance.allowTouchMove = true;
    }

    function changeSlide(time: number) {
        if (!swiper || isChange) {
            return;
        }

        isChange = true;
        disableSwiper(swiper);
        swiper.slides[swiper.previousIndex].classList.add('is-out');
        canvas?.classList.add('is-out');

        if (!isWorldActive) {
            offscreen?.leaveScene();
        }

        setTimeout(() => {
            swiper.slides[swiper.previousIndex].classList.remove('is-show');
            swiper.slides[swiper.previousIndex].classList.remove('is-out');
            swiper.slides[swiper.previousIndex].dispatchEvent(new Event('room-hide'));

            swiper.slides[swiper.realIndex].classList.add('is-in');
            swiper.slides[swiper.realIndex].classList.add('is-show');
            requestAnimationFrame(() => {
                swiper.slides[swiper.realIndex].dispatchEvent(new Event('room-show'));
            });

            if (canvas) {
                canvas.classList.remove('is-out');
                canvas.classList.add('is-in');
                canvas.classList.add('is-show');
            }

            offscreen?.enterScene(swiper.realIndex);
        }, time / 2);

        setTimeout(() => {
            swiper.slides[swiper.realIndex].classList.remove('is-in');
            canvas?.classList.remove('is-in');
            enableSwiper(swiper);
            isChange = false;
        }, time);
    }

    await onResize();

    document.addEventListener('main-slide-change', (event) => {
        const screenIndex = (event as any).detail as number;
        offscreen?.setState({ shouldRender: screenIndex === 0 });
    });

    window.addEventListener('resize', onResize);
    document.addEventListener('visibilitychange', onVisibilityChange);

    if (searchParams.get('debug') === 'true') {
        initStats();
    }

    const sliderContainer = document.querySelector<HTMLElement>('.js-rooms-slider');
    const sliderPagination = document.querySelector<HTMLElement>('.js-rooms-slider-pagination');

    if (sliderContainer && (window as any).Swiper) {
        const isWebGLPage = document.body.classList.contains('three-page');
        const slides = Array.from(sliderContainer.querySelectorAll<HTMLElement>('.swiper-slide'));
        swiper = new (window as any).Swiper(sliderContainer, {
            initialSlide: getInitialSlide(),
            speed: 600,
            slidePrevClass: 'is-prev',
            slideActiveClass: 'is-active',
            slideNextClass: 'is-next',
            virtualTranslate: isWebGLPage,
            slidesPerView: 1,
            touchEventsTarget: 'wrapper',
            // autoplay: {
            //     delay: 7000,
            //     disableOnInteraction: false,
            //     waitForTransition: false,
            // },
            allowTouchMove: true,
            noSwiping: false,
            ...(isWebGLPage && {
                breakpoints: {
                    1024: {
                        allowTouchMove: true,
                        noSwiping: false,
                        virtualTranslate: false,
                    },
                },
            }),
            pagination: {
                el: sliderPagination,
                type: 'bullets',
                clickable: true,
                bulletActiveClass: 'is-active',
                renderBullet: (index: number, className: string) => {
                    return `
                        <button class="video-nav__item rooms-nav__item ${className}">
                            <span class="video-nav__link link">
                                <span class="link__text">${slides[index]?.dataset.paginationName || 'Unknown'}</span>
                                <span class="link__icon">
                                    <i class="icon icon--caret-up"></i>
                                </span>
                            </span>
                        </button>
                    `;
                },
            },
            on: {
                slidePrevTransitionStart() {
                    changeSlide(animationTime);
                },
                slideNextTransitionStart() {
                    changeSlide(animationTime);
                },
            },
        });

        slides.forEach((el) => {
            const roomLinks = Array.from(el.querySelectorAll('.js-room-link'));
            roomLinks.forEach((link) => {
                link.addEventListener('mouseenter', () => {
                    if (swiper) {
                        disableSwiper(swiper);
                    }
                });
                link.addEventListener('mouseleave', () => {
                    if (!el.classList.contains('is-zoomed')) {
                        if (swiper) {
                            enableSwiper(swiper);
                        }
                    }
                });
            });
        });

        document.addEventListener('room-zoom-in', () => {
            if (swiper) {
                disableSwiper(swiper);
            }
        });

        document.addEventListener('room-zoom-out', () => {
            if (swiper) {
                enableSwiper(swiper);
            }
        });
    }

    return {} as const;
};
