import flatpickr from 'flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import axios from 'axios';
import { withLeadingZero } from '../utils/with-leading-zero';

if (document.documentElement.lang === 'ru') {
    flatpickr.localize(Russian);
}

async function fetchEventsData(url: string, selectedDates: Date[], form: HTMLFormElement) {
    try {
        const response = await axios.post(url, {
            is_ajax: 'Y',
            selectedDates,
        });

        if (response.data.success) {
            //
        } else {
            throw new Error(response.data.message);
        }
    } catch (err) {
        //
    } finally {
        //
    }
}

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll('.js-datepicker-events')).forEach((el) => {
        const form = el.closest('form');
        const eventDates = (el.getAttribute('data-event-dates') || '').replace(/ /gi, '').split(',');

        flatpickr(el, {
            disableMobile: true,
            onDayCreate: (dObj, dStr, fp, dayElem) => {
                // Utilize dayElem.dateObj, which is the corresponding Date
                const currentDate = `${dayElem.dateObj.getDate()}.${withLeadingZero(
                    dayElem.dateObj.getMonth() + 1,
                )}.${dayElem.dateObj.getFullYear()}`;

                if (eventDates.indexOf(currentDate) !== -1) {
                    dayElem.setAttribute('filter-date', currentDate);
                    dayElem.classList.add('js-events-date');
                    dayElem.innerHTML += "<span class='dp-note'></span>";
                }
            },
            onChange: (selectedDates, dateStr, instance) => {
                document.getElementsByName('FILTER_DATE')[0].value = dateStr;
                if (form) {
                    fetchEventsData(form.action, selectedDates, form);
                }
                window.filterEvents();
            },
        });
    });
}

function destroy(container: HTMLElement | Document = document) {
    //
}

const _module = { init, destroy };

export default _module;
