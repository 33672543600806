import gsap from 'gsap';

function init() {
    const switcher = document.querySelector('.js-switcher');
    if (switcher) {
        const checkbox = switcher.querySelector('.js-contact-switcher__input');
        const titles = switcher.querySelectorAll('.js-contact-switcher__title');
        if (checkbox) {
            checkbox.addEventListener('change', () => {
                let event = new Event('changeContacts', { bubbles: true });
                checkbox.dispatchEvent(event);
                titles.forEach((title) => {
                    if (title.classList.contains('is-active')) {
                        title.classList.remove('is-active');
                    } else {
                        title.classList.add('is-active');
                    }
                });
            });
        }
    }

    const contacts = document.querySelector('.js-contacts');
    if (contacts) {
        const contactsContainers = contacts.querySelectorAll('.js-contact__body');
        document.addEventListener('changeContacts', function (event) {
            contactsContainers.forEach((contact) => {
                if (contact.classList.contains('active')) {
                    gsap.to(contact, {
                        opacity: 0,
                        duration: 0.2,
                        display: 'none',
                        onComplete: () => {
                            contact.classList.remove('active');
                        },
                    });
                } else {
                    gsap.to(contact, {
                        opacity: 1,
                        duration: 0.2,
                        display: 'block',
                        onComplete: () => {
                            contact.classList.add('active');
                        },
                    });
                }
            });
        });
    }
}

const _module = { init };

export default _module;
