export const fetchAjaxFormSender = () =>
    import(
        '../modules/ajax-form-sender'
        /* webpackChunkName: "ajax-form-sender" */
    );

export const fetchValidator = () =>
    import(
        '../modules/validator'
        /* webpackChunkName: "validator" */
    );

export const fetchImask = () =>
    Promise.all([
        import(
            'imask/esm/imask'
            /* webpackChunkName: "imask" */
        ),
        import(
            'imask/esm/masked/number'
            /* webpackChunkName: "imask-number" */
        ),
    ]).then(([m]) => m);
