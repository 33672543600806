// eslint-disable-next-line no-use-before-define
let _this: AudioEl;
class AudioEl {
    container: HTMLElement;

    audioEl: HTMLAudioElement | null;

    audioTrigger: HTMLElement | null;

    rewindTrigger: HTMLElement | null;

    forwardTrigger: HTMLElement | null;

    bar: HTMLInputElement | null;

    speedUp: HTMLElement | null;

    duration: HTMLElement | null;

    raf: number | null;

    constructor(container: HTMLElement) {
        _this = this;
        this.container = container;
        this.audioEl = this.container.querySelector('audio');
        this.audioTrigger = this.container.querySelector('.js-audio-trigger');
        this.rewindTrigger = this.container.querySelector('.js-audio-rewind');
        this.forwardTrigger = this.container.querySelector('.js-audio-fast-forward');
        this.bar = this.container.querySelector('.js-audio-range');
        this.speedUp = this.container.querySelector('.js-audio-speed-up');
        this.duration = this.container.querySelector('.js-audio-duration');
        this.raf = null;

        if (this.audioEl) {
            if (this.audioEl.readyState > 0) {
                this._init();
            } else {
                this.audioEl.addEventListener('loadedmetadata', () => {
                    this._init();
                });
            }
        }
    }

    _init() {
        this.audioTrigger?.addEventListener('click', this.playAudio);
        this.rewindTrigger?.addEventListener('click', () => this.rewindAudio(false));
        this.forwardTrigger?.addEventListener('click', () => this.rewindAudio(true));
        this.speedUp?.addEventListener('click', this.setSpeed);
        this.audioEl?.addEventListener('progress', this.displayBufferedAmount);
        const audioDuration = this.calculateTime(this.audioEl?.duration);

        this.bar?.addEventListener('input', () => {
            this.showRangeProgress();
            if (this.audioEl && this.raf && !this.audioEl.paused) {
                cancelAnimationFrame(this.raf);
            }
        });

        this.bar?.addEventListener('change', () => {
            if (!this.bar || !this.audioEl) return;
            this.audioEl.currentTime = this.audioEl.duration * (+this.bar.value / 100);

            if (!this.audioEl.paused) {
                requestAnimationFrame(this.whilePlaying);
            }
        });

        if (this.duration) this.duration.innerHTML = `${audioDuration}`;
    }

    calculateTime(secs: number | undefined) {
        if (!secs) return;
        const minutes = Math.floor(secs / 60);
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${minutes}:${returnedSeconds}`;
    }

    showRangeProgress() {
        if (!_this.bar) return;

        _this.container.style.setProperty('--bar-width', `${_this.bar.value}%`);
    }

    setBarValue() {
        if (!_this.bar || !_this.audioEl) return;
        _this.bar.value = `${Math.floor((_this.audioEl.currentTime / _this.audioEl.duration) * 100)}`;
    }

    whilePlaying() {
        if (!_this.bar || !_this.audioEl) return;
        _this.setBarValue();
        _this.showRangeProgress();
        _this.container.style.setProperty('--bar-width', `${_this.bar.value}%`);

        if (_this.audioEl.currentTime === _this.audioEl.duration) {
            _this.setPause();
        } else {
            _this.raf = requestAnimationFrame(_this.whilePlaying);
        }
    }

    displayBufferedAmount() {
        if (!_this.audioEl || !_this.bar) return;
        const bufferedAmount = Math.floor(_this.audioEl.buffered.end(_this.audioEl.buffered.length - 1));

        _this.container.style.setProperty('--buffered-width', `${(bufferedAmount / +_this.bar.max) * 100}%`);
    }

    setSpeed() {
        if (!_this.audioEl) return;

        if (_this.speedUp?.classList.contains('is-speed')) {
            _this.audioEl.defaultPlaybackRate = 1.0;
            _this.speedUp?.classList.remove('is-speed');
        } else {
            _this.audioEl.defaultPlaybackRate = 2.0;
            _this.speedUp?.classList.add('is-speed');
        }
    }

    setPause() {
        _this.audioEl?.pause();
        _this.audioTrigger?.classList.remove('is-play');
        if (_this.raf) cancelAnimationFrame(_this.raf);
    }

    playAudio() {
        if (!_this.audioEl) return;

        if (!_this.audioEl.paused) {
            _this.setPause();
        } else {
            _this.audioEl?.play();
            requestAnimationFrame(_this.whilePlaying);
            _this.audioTrigger?.classList.add('is-play');
        }
    }

    rewindAudio(forward: boolean) {
        if (!_this.audioEl) return;

        let newCurrentTime = 0;

        if (forward) {
            if (_this.audioEl.currentTime + 15 >= _this.audioEl.duration) {
                newCurrentTime = 0;
            } else {
                newCurrentTime = _this.audioEl.currentTime + 15;
            }
        } else {
            newCurrentTime = _this.audioEl.currentTime - 15 <= 0 ? 0 : _this.audioEl.currentTime - 15;
        }
        _this.audioEl.currentTime = newCurrentTime;
        _this.setBarValue();
        _this.showRangeProgress();
    }
}

function init(container: HTMLElement | Document = document) {
    const audioBlocks = container.querySelectorAll<HTMLElement>('.js-audio-container');
    audioBlocks.forEach((el) => {
        new AudioEl(el);
    });
}

const _module = { init };

export default _module;
