import debounce from 'lodash.debounce';
import axios from 'axios';

let typeRequest: string |null | undefined;
let messageContainer: HTMLElement | null;
let loader: HTMLElement | null | undefined;

function init() {
    const innContainer = Array.from(document.querySelectorAll<HTMLElement>('.js-company-container'));
    innContainer.forEach((container) => {
        const inputCompany = container.querySelector<HTMLInputElement>('.js-company-input');
        const resultContainer = container.querySelector<HTMLElement>('.js-company-result');
        typeRequest = inputCompany?.getAttribute('data-type-request');
        messageContainer = container.querySelector<HTMLElement>('.app-message');
        const overlay = container.querySelector<HTMLElement>('.js-overlay');

        const urlRequest: string | undefined = inputCompany?.dataset.url;

        if (typeRequest) {
            if (typeRequest === 'inn') {
                inputCompany?.addEventListener('input', debounce(onChange, 500))
                inputCompany?.addEventListener('click', showPopupInn);
            } else {
                inputCompany?.addEventListener('input', debounce(onChangeCompany, 500));
                inputCompany?.addEventListener('click', showPopupCompany);
            }
        }
        ;

        function onChange(e: Event) {
            const target: any = e.target;
            const container = target.closest('.js-company-container');
            const resultInner = container.querySelector('.js-company-result-inner');
            const messageContainer = container.querySelector('.app-message');
            loader = container.querySelector('.js-company-loader');
            if (loader) loader.classList.remove('is-show');

            if (inputCompany && inputCompany.value.length > 9) {
                const dataCompany = {
                    innValue: ""
                }

                function showResult() {
                    if (inputCompany) dataCompany.innValue = inputCompany.value;
                    if (resultContainer && urlRequest) {
                        getCompanies(resultContainer, urlRequest, dataCompany, resultInner, messageContainer);
                    }
                }
                showResult();
            }
        }

        function onChangeCompany(e: Event) {
            if (loader) loader.classList.remove('is-show');
            const target: any = e.target;
            const container = target.closest('.js-company-container');
            const resultInner = container.querySelector('.js-company-result-inner');
            const messageContainer = container.querySelector('.app-message');        loader = container.querySelector('.js-company-loader');

            if (inputCompany && inputCompany.value.length > 2) {
                const dataCompany = {
                    companyNameValue: ""
                }

                function showResult() {
                    if (inputCompany) dataCompany.companyNameValue = inputCompany.value;
                    if (resultContainer && urlRequest) {
                        getCompanies(resultContainer, urlRequest, dataCompany, resultInner, messageContainer);
                    }
                }
                showResult();
            }
        }

        function showPopupInn() {
            if (inputCompany && inputCompany.value.length > 9) {
                if (resultContainer && messageContainer?.textContent == '') {
                    resultContainer.style.display = 'block';
                }
            }
        }

        function showPopupCompany() {
            if (inputCompany && inputCompany.value.length > 2) {
                if (resultContainer && messageContainer?.textContent == '') {
                    resultContainer.style.display = 'block';
                }
            }
        }

        overlay?.addEventListener('click', () => {
            if (resultContainer) {
                resultContainer.style.display = 'none';
            }
        });

        resultContainer?.addEventListener('click', (e) => {
            const target: HTMLElement | EventTarget | any = e.target;
            const trigger = target.closest('.js-btn-company');
            const urlRequest = resultContainer.getAttribute('data-url');
            typeRequest = inputCompany?.getAttribute('data-type-request');
            const container = target.closest('.js-company-container');
            loader = container.querySelector('.js-company-loader');

            if (trigger) {
                if (resultContainer) {
                    resultContainer.style.pointerEvents = 'none'

                    if (inputCompany && trigger && urlRequest && trigger.id && typeRequest) {
                        if (typeRequest === 'inn') {
                            const dataCompany = {
                                innValue: inputCompany.value,
                                id: trigger.id
                            }
                            getNamesForms(resultContainer, urlRequest, dataCompany);
                        } else {
                            const dataCompany = {
                                companyNameValue: inputCompany.value,
                                id: trigger.id
                            }
                            getNamesForms(resultContainer, urlRequest, dataCompany);
                        }
                    }
                }
            }
        });
    });

    // запрос на сервер
    const getCompanies = async (inner: HTMLElement, url: string | undefined, data: object | undefined, resultInner: HTMLElement, messageContainer: HTMLElement) => {
        if (url) {
            if (loader) loader.classList.add('is-show');
            const response = await axios.get(url, {
                params: data,
            })
                .then(response => response.data)
                .then(data => {
                    if (data.status === 'success') {
                        inner.style.display = 'block';
                        if (messageContainer) messageContainer.textContent = '';
                        if (resultInner) resultInner.innerHTML = '';

                        addInnerResult(resultInner);
                        const resultArr: any[] = data.data;
                        resultArr.forEach(result => {
                            if (result.ID !== undefined && result.NAME !== undefined && result.ADDRESS !== undefined) {
                                addInnItem(resultInner, result.ID, result.NAME, result.ADDRESS);
                            }
                        });
                    } else {
                        if (messageContainer) {
                            let message = '';
                            data.errors.forEach((error) => {
                                message += error.message;
                            });

                            messageContainer.style.color = '#df1831';
                            inner.style.display = 'none';
                            messageContainer.textContent = `${message}`;
                        }
                    }
                })
                .finally(() => {
                    if (loader) loader.classList.remove('is-show');
                });
        }
    };

    // запрос на сервер
    const getNamesForms = async (inner: HTMLElement, url: string | undefined, data: object | undefined) => {
        if (url) {
            if (loader) loader.classList.add('is-show');
            const response = await axios.get(url, {
                params: data,
            })
                .then(response => response.data)
                .then(data => {
                    if (data.status === 'success') {
                        inner.style.display = 'none';
                        const resultArr: any[] = data.data;
                        resultArr.forEach(result => {
                            if (result.INPUT_NAME && result.TYPE) {
                                if (result.TYPE === "TEXT") {
                                    changeInputs(inner, result.INPUT_NAME, result.VALUE);
                                } else if (result.TYPE === "LOCATION") {
                                    if ((window as any).BX && (window as any).BX.locationSelectors) {
                                        let obLocation = (window as any).BX.locationSelectors[result.INPUT_NAME];
                                        if (obLocation) {
                                            obLocation.setValueByLocationId(result.VALUE);
                                        }
                                    }
                                }
                            }
                        })
                    } else {
                        if (messageContainer) {
                            messageContainer.style.color = '#df1831';
                            inner.style.display = 'none';
                            messageContainer.textContent = `${data.message}`;
                        }
                    }
                })
                .finally(() => {
                    inner.style.pointerEvents = 'auto';
                    if (loader) loader.classList.remove('is-show');
                });
        }
    };
}

function addInnerResult(container: HTMLElement | null | undefined) {
    const inner = document.createElement('div');
    inner.classList.add('search-drop__inner');
    inner.classList.add('js-result-inner');

    if (container) {
        container.appendChild(inner);
    }

}

function addInnItem(container: HTMLElement | null | undefined, id: string, name: string, address: string) {
    const inner = document.createElement('div');
    inner.classList.add('search-drop__inner');

    if (container) {
        const inner = container.querySelector('.js-result-inner');

        const item = document.createElement('div');
        item.classList.add('search-drop__item');
        item.innerHTML = `
            <button type="button" class="link cb-input company-result-trigger js-btn-company" id="${id}">
                <div>${name}</div>
                <div>${address}</div>
            </button>
        `;
        inner?.appendChild(item);
    }

}

function changeInputs(target: HTMLElement, inputName: string, inputValue: string) {
    const form = target.closest('form');
    const inputs = form?.querySelectorAll<HTMLInputElement>(`[data-code="${inputName}"`);

    if (inputs) inputs.forEach(input => {
        input.value = inputValue;
        input.classList.add('is-filled')
    });
}

function destroy() {
}

const _module = { init, destroy };

export default _module;
