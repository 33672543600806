export function initStats() {
    // eslint-disable-next-line global-require
    const Stats = require('three/examples/jsm/libs/stats.module').default;
    const stats = new Stats();
    document.body.appendChild(stats.domElement);

    const animate = () => {
        stats.update();
        requestAnimationFrame(animate);
    };

    animate();
}
