import axios from 'axios';
import delegate from 'delegate';
import type { BaseResponse } from '../types';

type AjaxContentResponse = BaseResponse & {
    data: {
        catalogHtml: string;
        loadMoreHtml: string;
    };
};

document.addEventListener('DOMContentLoaded', () => {
    Array.from(document.querySelectorAll<HTMLElement>('.js-ajax-content-block')).forEach((container) => {
        const contentContainer = container.querySelector<HTMLElement>('.js-ajax-content');
        const loadMoreLinkContainer = container.querySelector<HTMLElement>('.js-ajax-load-more-link-container');
        let loadMoreBtn = container.querySelector<HTMLAnchorElement>('a.js-ajax-load-more-link');

        function setLoadingState() {
            setTimeout(() => {
                if (loadMoreBtn) {
                    loadMoreBtn.classList.add('is-overlay');
                }
            }, 0);
        }

        function removeLoadingState() {
            if (loadMoreBtn) {
                loadMoreBtn.classList.remove('is-overlay');
            }
        }

        async function onLoadMoreBtnClick(event: any) {
            event.preventDefault();
            event.stopPropagation();
            const target = event.delegateTarget as HTMLAnchorElement;
            const url = target.href;

            setLoadingState();

            try {
                const response = await axios.get<AjaxContentResponse>(url, { params: { is_ajax: 'y' } });
                const { loadMoreHtml, catalogHtml } = response.data.data;

                if (contentContainer) {
                    const template = document.createElement('template');
                    template.innerHTML = catalogHtml;
                    contentContainer.appendChild(template.content);
                    (window as any).lazyloadItems('.js-ajax-content [data-lazyload-first]');
                }

                if (loadMoreLinkContainer) {
                    const template = document.createElement('template');
                    template.innerHTML = loadMoreHtml;
                    loadMoreLinkContainer.appendChild(template.content);
                    loadMoreBtn = loadMoreLinkContainer.querySelector<HTMLAnchorElement>('a.js-ajax-load-more-link');

                    if (!loadMoreHtml) {
                        loadMoreLinkContainer.parentElement?.removeChild(loadMoreLinkContainer);
                    }
                }
            } finally {
                removeLoadingState();
            }
        }

        delegate(container, 'a.js-ajax-load-more-link', 'click', onLoadMoreBtnClick);
    });
});
