document.addEventListener('DOMContentLoaded', () => {
    if (document.body.classList.contains('catalog-page')) {
        if (localStorage.getItem('IS_PREV_PAGE_CATALOG_DETAIL') === 'true') {
            const scrollY = localStorage.getItem('scrollY');

            if (scrollY) {
                window.scrollTo({ top: parseFloat(scrollY), behavior: 'auto' });
            }
        }

        localStorage.removeItem('scrollY');

        window.addEventListener('beforeunload', () => {
            localStorage.setItem('scrollY', `${window.scrollY}`);
        });
    }

    window.addEventListener('beforeunload', () => {
        localStorage.setItem(
            'IS_PREV_PAGE_CATALOG_DETAIL',
            `${document.body.classList.contains('catalog-detail-page')}`,
        );
    });
});
