import Axios from 'axios';

import type { BaseResponse } from '../types';

type CatalogResponse = BaseResponse & {
    data: {
        title: string;
        catalogHtml: string;
        filterHtml: string;
        crumbs: string;
        newUrl?: string;
        action?: string;
        icons?: Record<string, any>;
        filterEmpty: boolean;
    };
};

const { CancelToken } = Axios;
let cancelActualRequest: any;

const viewInputs = Array.from(document.querySelectorAll<HTMLInputElement>('.js-favorites-view-input'));
const catalogList = document.querySelector('.js-catalog-list');
const catalogTitle = document.querySelector('.js-catalog-title');
const breadcrumbs = document.querySelector('.js-breadcrumbs-list');
let timer: NodeJS.Timeout;

function serializeViewInput() {
    const checkedInput = viewInputs.find((input) => input.checked);
    return checkedInput ? { [checkedInput.name]: checkedInput.value } : {};
}

function setLoadingState() {
    setTimeout(() => {
        clearTimeout(timer);

        timer = setTimeout(() => {
            catalogList?.classList.add('loading');
        }, 200);
    }, 0);
}

async function fetchData<T = any>(url: string, action: string, serializeForm = true) {
    return Axios.get<T>(url, {
        params: {
            action,
            ...(serializeForm ? serializeViewInput() : {}),
            is_ajax: 'Y',
            ajax: action === 'n',
        },
        cancelToken: new CancelToken((c) => {
            cancelActualRequest = c;
        }),
    });
}

function removeLoadingState() {
    setTimeout(() => {
        clearTimeout(timer);
        catalogList?.classList.remove('loading');
    }, 0);
}

async function fetchActualData(url: string, serializeForm = true) {
    cancelActualRequest?.();
    setLoadingState();

    try {
        const response = await fetchData<CatalogResponse>(url, 'getCatalogData', serializeForm);
        const { catalogHtml, title, crumbs, newUrl, icons } = response.data.data;

        if (catalogList) {
            catalogList.innerHTML = catalogHtml || '';
            (window as any).lazyloadItems?.('[data-lazyload-first]');
        }

        if (catalogTitle) {
            catalogTitle.textContent = title;
        }

        if (breadcrumbs && crumbs) {
            breadcrumbs.innerHTML = crumbs;
        }

        if (newUrl) {
            window.history.pushState(null, title || '', newUrl);
        }

        if (title) {
            document.title = title;
        }

        if (icons) {
            // eslint-disable-next-line guard-for-in, no-restricted-syntax
            for (const key in icons) {
                const values = icons[key];
                values.forEach((id: string | number) => {
                    const elements = document.querySelectorAll(`.${key}[data-item-id="${id}"]`);
                    if (elements !== null) {
                        elements.forEach((element) => {
                            element.classList.add('is-active');
                        });
                    }
                });
            }
        }
    } catch (err) {
        if (!Axios.isCancel(err)) {
            throw err;
        }
    } finally {
        removeLoadingState();
    }
}

function init() {
    viewInputs.forEach((input) => {
        input.addEventListener('change', () => {
            fetchActualData((window as any).location.href);
        });
    });
}

function destroy() {
    //
}

const _module = { init, destroy };

export default _module;
