function onClick(this: Element, event: any) {
    event.preventDefault();
    window.history.back();
}

function init() {
    const backButtons = Array.from(document.querySelectorAll<HTMLAnchorElement>('.js-back-link'));
    backButtons.forEach((btn) => {
        btn.addEventListener('click', onClick);
    });
}

function destroy() {
    const backButtons = Array.from(document.querySelectorAll<HTMLAnchorElement>('.js-back-link'));
    backButtons.forEach((btn) => {
        btn.removeEventListener('click', onClick);
    });
}

const _module = { init, destroy };

export default _module;
