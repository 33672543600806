export function setMaxHeightTitle(container: HTMLElement | Document = document) {
    const slides = Array.from(container.querySelectorAll('.js-mp__slider-slide'));
    let maxvalue = 0;
    if (slides.length > 1) {
        slides.forEach((slide) => {
            const title = slide.querySelector('.js-mp__slider-card__title');
            const titleHeight = title.offsetHeight;
            if (titleHeight > maxvalue) {
                maxvalue = titleHeight;
            }
        });
        slides.forEach((slide) => {
            const title = slide.querySelector('.js-mp__slider-card__title');
            const titleHeight = (title.style.height = maxvalue + 'px');
        });
    }
}

export function getScaleWidth(container: HTMLElement | Document = document) {
    const computedStyle = getComputedStyle(container).getPropertyValue('--offset-active-img');
    const scaleWidth = computedStyle ? parseFloat(computedStyle) : 0;
    return scaleWidth;
}

export function setMaxWidthFrameDescr(container: HTMLElement | Document = document) {
    const slides = Array.from(container.querySelectorAll('.js-mp__slider-slide'));
    let maxDescrWidth = 0;
    let maxTtitleWidth = 0;
    const scaleWidth = getScaleWidth(container);

    if (slides.length > 1) {
        slides.forEach((slide) => {
            const title = slide.querySelector('.js-mp__slider-card__title');
            const descr = slide.querySelector('.js-mp__slider-card__descr');
            if (!slide.classList.contains('is-active')) {
                const descrWidth = descr.offsetWidth;
                const titleWidth = title.offsetWidth;

                if (descrWidth > maxDescrWidth) {
                    maxDescrWidth = descrWidth;
                }
                if (titleWidth > maxTtitleWidth) {
                    maxTtitleWidth = titleWidth;
                }
            }
        });
        slides.forEach((slide) => {
            const descr = slide.querySelector('.js-mp__slider-card__descr');
            const title = slide.querySelector('.js-mp__slider-card__title');
            const descrWidth = (descr.style.maxWidth = maxDescrWidth - scaleWidth / 2 + 'px');
            const titleWidth = (title.style.maxWidth = maxTtitleWidth + 'px');
        });
    }
}

export function getTypes(slides) {
    let typesOfFilter = [];
    if (slides.length > 0) {
        slides.forEach((slide) => {
            const type = slide.getAttribute('data-type');
            typesOfFilter.push(type);
        });
    }

    const uniqueStrings = [...new Set(typesOfFilter)];
    return uniqueStrings;
}

export function checkDeviceWidth() {
    return window.innerWidth;
}
